import { Injectable } from '@angular/core';
import { Storage, ref, deleteObject, uploadBytes, getDownloadURL } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public storagePath = '';

  constructor(private storage: Storage) {}

  async getDownloadURL(fileId: string): Promise<string> {
    const path = [this.storagePath, fileId].join('/').replace(/\/\//g, '/');
    const storageRef = ref(this.storage, path);
    return getDownloadURL(storageRef);
  }

  upload(path: string, data: Blob | Uint8Array | ArrayBuffer) {
    const fullPath = [this.storagePath, path].join('/').replace(/\/\//g, '/');
    const storageRef = ref(this.storage, fullPath);
    return uploadBytes(storageRef, data).then((res) => res.ref.fullPath);
  }

  delete(path: string): Promise<void> {
    const fullPath = [this.storagePath, path].join('/').replace(/\/\//g, '/');
    const storageRef = ref(this.storage, fullPath);
    return deleteObject(storageRef);
  }
}
