import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { CountryService } from '@core/services/country.service';
import { map } from 'rxjs';
@Pipe({
  name: 'countryFlag',
})
export class CountryFlagPipe implements PipeTransform {
  private jsonUrl = 'assets/countries/countries.json';
  http = inject(HttpClient);
  transform(countryCca2: string) {
    return this.http.get<any>(this.jsonUrl).pipe(
      map((countries) => {
        const country = countries.find((c: any) => c.cca2 === countryCca2);
        return country ? country.flag : null;
      }),
    )
  }
}
