import { Pipe, PipeTransform } from '@angular/core';
import { Storage, ref, getDownloadURL } from '@angular/fire/storage';

@Pipe({
  name: 'imageLoaderUrl'
})
export class ImageLoaderUrl implements PipeTransform {
  constructor(private storage: Storage) {}

  transform(id: string): Promise<string> {
    const path = [id].join('/').replace(/\/\//g, '/');
    const storageRef = ref(this.storage, path);
    return getDownloadURL(storageRef);
  }
}
