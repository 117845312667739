<img class="w-full h-40" [src]="headerImageUrl" />
<div class="flex flex-col items-center gap-4 mx-4">
  <div class="bg-secondary rounded-full w-32 h-32 flex items-center justify-center mt-12">
    <ion-icon class="text-7xl" name="gt-candado"></ion-icon>
  </div>
  <div class="text-center text-destacadoM font-bree">{{ title }}</div>
  <div class="text-center text-sm">{{ desc }}</div>
  <div class="text-center font-bree mt-4">
    {{ bottomDesc }}
  </div>
</div>
