import { Injectable, inject } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { COLLECTIONS } from '@core/core.constants';
import { Category } from '@core/models/common.model';
import { FormInscription, Inscription } from '@core/models/inscription.model';
import { AuthService } from '@core/services/auth.service';
import { FirestoreService } from '@core/services/firestore.service';
import { Observable, combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { EditionService } from './edition.service';
import { limit } from 'firebase/firestore';
import { Payment } from '@core/models/payment.model';

@Injectable({
  providedIn: 'root',
})
export class InscriptionService {
  auth = inject(AuthService);
  firestore = inject(FirestoreService);
  editionService = inject(EditionService);

  public myCurrentInscription$: Observable<Inscription | null> = combineLatest([
    this.editionService.currentEdition$,
    this.auth.uid$,
  ]).pipe(
    switchMap(([edition, uid]) =>
      this.firestore.findDocument<Inscription>(
        COLLECTIONS.INSCRIPTIONS,
        where('userId', '==', uid),
        where('editionId', '==', edition.id),
      ),
    ),
    shareReplay(1),
  );

  public myCurrentInscriptionIsAccepted$: Observable<FormInscription | null> = combineLatest([
    this.editionService.currentEdition$,
    this.auth.uid$,
  ]).pipe(
    switchMap(([edition, uid]) =>
      this.firestore.findDocument<FormInscription>(
        COLLECTIONS.INSCRIPTIONS,
        where('userId', '==', uid),
        where('editionId', '==', edition.id),
        where('statusPayment', '==', 'PAID'),
      ),
    ),
    shareReplay(1),
  );

  public hasValidInscription$: Observable<boolean> = this.myCurrentInscription$.pipe(
    map((inscription) => !!inscription && inscription.statusPayment === 'PAID'),
  );

  public myCurrentCategory$: Observable<Category | null> = this.myCurrentInscription$.pipe(
    map((res) => res?.category ?? null),
  );

  public pendingPayment$ = combineLatest([this.myCurrentInscription$, this.auth.uid$]).pipe(
    switchMap(([inscription, uid]) =>
      this.firestore.listDocuments<Payment>(
        COLLECTIONS.PAYMENTS,
        where('userId', '==', uid),
        where('inscriptionId', '==', inscription!.id),
        where('statusPayment', '==', 'PENDING'),
        where('type', '==', 'BANK'),
        orderBy('createdAt', 'desc'),
        limit(1),
      ),
    ),
    map((payments) => payments[0]),
  );

  getParticipant(participantId: string) {
    return this.myCurrentInscription$.pipe(
      map(
        (inscriptions) =>
          [inscriptions!.pilot, inscriptions!.copilot, ...inscriptions!.participants].find(
            (p) => p.id === participantId,
          )!,
      ),
    );
  }

  createInscription(inscriptionData: Omit<FormInscription, 'id' | 'userId' | 'editionId'>) {
    return combineLatest([this.editionService.currentEdition$, this.auth.uid$]).pipe(
      switchMap(([edition, uid]) =>
        this.firestore.create<FormInscription>(COLLECTIONS.INSCRIPTIONS, {
          userId: uid,
          editionId: edition.id,
          ...inscriptionData,
          statusInscription: 'DRAFT',
        }),
      ),
    );
  }

  saveInscription(data: Inscription): Promise<void> {
    return this.firestore.update<Inscription>(COLLECTIONS.INSCRIPTIONS, {
      ...data,
      statusInscription: 'PENDING',
    });
  }

  updateInscription(inscriptionData: Partial<FormInscription>) {
    return this.firestore.update<Inscription>(COLLECTIONS.INSCRIPTIONS, inscriptionData);
  }
}
