import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '@core/services/firestore-storage.service';

@Pipe({
  name: 'imageDocUrl',
})
export class ImageDocUrlPipe implements PipeTransform {
  constructor(private storage: StorageService) {}

  transform(id: string): Promise<string> {
    return this.storage.getDownloadURL(id);
  }
}
