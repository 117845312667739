import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gt-placeholder',
  templateUrl: './placeholder.component.html',
  styles: `
  img {
    clip-path: polygon(0 0, 100% 0%, 100% 45%, 0% 100%);
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderComponent {
  @Input() headerImageUrl = '/assets/img/placeholder.png';
  @Input() iconName = 'gt-candado';
  @Input() title = 'Title';
  @Input() desc = 'Description';
  @Input() bottomDesc = 'bottom Text';
}
