<ion-tabs mode="ios">
  <ion-tab-bar slot="bottom" *ngIf="inscriptionsService.myCurrentInscriptionIsAccepted$ | async">
    <ion-tab-button tab="home">
      <ion-icon name="gt-tab-home"></ion-icon>
      {{ 'TABS.HOME' | transloco }}
    </ion-tab-button>
    <ion-tab-button tab="stages">
      <ion-icon name="gt-tab-stages"></ion-icon>
      {{ 'TABS.STAGES' | transloco }}
    </ion-tab-button>
    <ion-tab-button tab="booked">
      <ion-icon name="gt-tab-booked"></ion-icon>
      {{ 'TABS.BOOKINGS' | transloco }}
    </ion-tab-button>
    <ion-tab-button tab="bookings">
      <ion-icon name="gt-tab-bookings"></ion-icon>
      {{ 'TABS.BOOKED' | transloco }}
    </ion-tab-button>
    <ion-tab-button tab="messages">
      <ion-icon name="gt-tab-messages"></ion-icon>
      {{ 'TABS.MESSAGES' | transloco }}
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
