export const environment = {
  production: false,
  appType: 'maroc',
  platform: 'web',
  version: '0.0.0',
  defaultLang: 'es',
};

export const firebaseConfig = {
  apiKey: 'AIzaSyD0blkQdSi_8XZYgvaIJVdK9nm8WqTu4RQ',
  authDomain: 'grandtour-dev.firebaseapp.com',
  projectId: 'grandtour-dev',
  storageBucket: 'grandtour-dev.appspot.com',
  messagingSenderId: '545867808550',
  appId: '1:545867808550:web:e6ca64149d4625c8ec4be7',
};
