import { Injectable, inject } from '@angular/core';
import { limit, orderBy, where } from '@angular/fire/firestore';
import { COLLECTIONS, ENVIRONMENT } from '@core/core.constants';
import { Id } from '@core/models/common.model';
import { Edition, Stage } from '@core/models/edition.model';
import { Message } from '@core/models/message.model';
import { ReservationItem } from '@core/models/reservation-item.model';
import { FirestoreService } from '@core/services/firestore.service';
import { Observable, filter, map, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditionService {
  private firestore = inject(FirestoreService);
  private environment = inject(ENVIRONMENT);

  public edition$ = this.firestore
    .listDocuments<Edition>(
      COLLECTIONS.EDITIONS,
      where('isActive', '==', true),
      where('type', '==', this.environment.appType),
      limit(1),
    )
    .pipe(
      map((editions) => (editions[0] ? editions[0] : null)),
      shareReplay(1),
    );

  public currentEdition$ = this.edition$.pipe(
    filter((edition): edition is Edition => !!edition),
    filter((edition) => edition.enableInscriptions),
    map((edition) => this.prepareDates(edition)),
    shareReplay(1),
  );

  public editionStages$ = this.currentEdition$.pipe(
    switchMap((edition) =>
      this.firestore.listDocumentsWithId<Stage>(
        [COLLECTIONS.EDITIONS, edition.id, COLLECTIONS.EDITION_STAGES].join('/'),
        orderBy('stageNumber', 'asc'),
      ),
    ),
    shareReplay(1),
  );

  public messages$ = this.currentEdition$.pipe(
    switchMap((edition: Edition) =>
      this.firestore.listDocuments<Message>(
        COLLECTIONS.MESSAGES,
        where('editionId', '==', edition.id),
        orderBy('createdAt', 'asc'),
      ),
    ),
    shareReplay(1),
  );

  public reservationItems$ = this.currentEdition$.pipe(
    switchMap((edition: Edition) =>
      this.firestore.listDocumentsWithId<ReservationItem>(
        COLLECTIONS.RESERVATION_ITEMS,
        where('editionId', '==', edition.id),
      ),
    ),
    shareReplay(1),
  );

  private cachedItem: Record<Id, Observable<ReservationItem>> = {};
  getItem(itemId: string): Observable<ReservationItem> {
    if (!this.cachedItem[itemId]) {
      this.cachedItem[itemId] = this.firestore.getDocument<ReservationItem>(COLLECTIONS.RESERVATION_ITEMS, itemId);
    }
    return this.cachedItem[itemId];
  }

  private cachedStage: Record<Id, Observable<Stage>> = {};
  getStage(stageId: string): Observable<Stage> {
    if (!this.cachedStage[stageId]) {
      this.cachedStage[stageId] = this.currentEdition$.pipe(
        switchMap((edition: Edition) =>
          this.firestore.getDocument<Stage>(
            [COLLECTIONS.EDITIONS, edition.id, COLLECTIONS.EDITION_STAGES].join('/'),
            stageId,
          ),
        ),
      );
    }
    return this.cachedStage[stageId];
  }

  prepareDates(edition: Edition) {
    const convertedEdition = {
      ...edition,
      rates: edition.rates.map((rate) => {
        return {
          ...rate,
          startDate: new Date(rate.startDate),
          endDate: new Date(rate.endDate),
        };
      }),
    };
    return convertedEdition;
  }
}
