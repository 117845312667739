<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <div
        (click)="showLanguageSelector = true"
        class="font-poppins font-semibold text-sm bg-primary rounded py-1 px-2 mr-4 uppercase"
      >
        {{ translocoService.getActiveLang() }}
      </div>
      <ion-button [routerLink]="(user$ | async) === null ? ['/onboarding/login'] : ['/profile']">
        <ion-icon class="w-8 h-8" name="gt-user"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <img height="64" class="logo" src="/assets/logos/logo.svg" />
</ion-header>
<ion-alert
  cssClass="language-alert"
  [isOpen]="showLanguageSelector"
  [header]="'LANGUAGE.TITLE' | transloco"
  [inputs]="languages$ | async"
  (didDismiss)="showLanguageSelector = false"
></ion-alert>
