import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { UserPreferencesService } from '@core/use-case-services/user-preferences.service';
import { AlertController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'gt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  translocoService = inject(TranslocoService);
  alertController = inject(AlertController);
  userService = inject(UserService);
  authService = inject(AuthService);
  userPreferencesService = inject(UserPreferencesService);

  public isLogged = false;
  public showLanguageSelector = false;

  public user$ = this.userService.user$;
  public languages$ = this.translocoService.langChanges$.pipe(
    map((lang) => [
      {
        label: 'Español',
        type: 'radio',
        value: 'es',
        checked: lang === 'es',
        handler: () => {
          this.updateLang('es');
          this.alertController.dismiss();
        },
      },
      {
        label: 'English',
        type: 'radio',
        value: 'en',
        checked: lang === 'en',
        handler: () => {
          this.updateLang('en');
          this.alertController.dismiss();
        },
      },
      {
        label: 'Français',
        type: 'radio',
        value: 'fr',
        checked: lang === 'fr',
        handler: () => {
          this.updateLang('fr');
          this.alertController.dismiss();
        },
      },
      {
        label: 'Italiano',
        type: 'radio',
        value: 'it',
        checked: lang === 'it',
        handler: () => {
          this.updateLang('it');
          this.alertController.dismiss();
        },
      },
      {
        label: 'Português',
        type: 'radio',
        value: 'pt',
        checked: lang === 'pt',
        handler: () => {
          this.updateLang('pt');
          this.alertController.dismiss();
        },
      },
    ]),
  );

  private updateLang(lang: string) {
    this.translocoService.setActiveLang(lang);
    localStorage.setItem('lang', lang);
    if (this.isLogged) {
      this.userPreferencesService.createOrUpdatePreferences({
        language: lang,
      });
    }
  }

  ngOnInit(): void {
    this.authService.isLoggedIn$
      .pipe(
        switchMap((isLogged) => {
          this.isLogged = isLogged;
          if (isLogged) {
            return this.userPreferencesService.myLanguage$;
          }
          return of(localStorage.getItem('lang') ?? 'es');
        }),
        tap((lang) => {
          this.translocoService.setActiveLang(lang);
        }),
      )
      .subscribe();
  }
}
