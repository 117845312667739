import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { EditionService } from '@core/use-case-services/edition.service';
import { InscriptionService } from '@core/use-case-services/inscription.service';

@Component({
  selector: 'gt-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  constructor(
    public router: Router,
    public authService: AuthService,
    public editionService: EditionService,
    public inscriptionsService: InscriptionService,
  ) {}
}
